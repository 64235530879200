.tamaño-video {
    height: calc(100vh - 128px) !important;
}

.videoHome {
    width: 100% !important;
    object-fit: cover !important;
}

.no-clicker {
    opacity: 0.9 !important;
    pointer-events: none !important;
}

.video-container {
    width: 100vw !important;
    height: 80vh !important;
    overflow: hidden !important;
    position: relative !important;
}

.video-container iframe {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.video-container iframe {
    pointer-events: none !important;
}
.video-container iframe {
    position: absolute !important;
    top: -60px !important;
    left: 0 !important;
    width: 100% !important;
    height: calc(100% + 120px) !important;
}
.video-foreground {
    pointer-events: none !important;
}
