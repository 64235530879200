.mapa-width {
  width: 600px !important;
}

@media screen and (max-width: 991px) {
  .mapa-width {
    width: 600px !important;
  }
}

@media screen and (max-width: 500px) {
  .mapa-width {
    width: 100% !important;
  }
}
