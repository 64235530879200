.novedades-nav a {
  text-decoration: none;
  color: white;
}

.novedades-nav a:hover {
  color: white;
}

.fondo-novedades {
  filter: grayscale(100%);
  transition: 0.5s all;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.tamaño-card-novedades {
  width: 47%;
}

@media screen and (max-width: 991px) {
  .tamaño-card-novedades {
    width: 100%;
  }
}

.fondo-novedades:hover {
  cursor: pointer;
  filter: grayscale(0%);
  transition: 0.5s all;
}

@media screen and (max-width: 575px) {
  #novedades .container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.boton-vermas {
  width: 276px;
  background-color: #ff6700 !important;
  mix-blend-mode: normal !important;
  border: 2px solid #ff6700 !important;
  backdrop-filter: blur(37.2289px) !important;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.spinner {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagen-lista-noticia {
  width: 200px;
  height: 200px;
}
