.padding-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media screen and (max-width: 768px) {
  .padding-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
