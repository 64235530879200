

.boton-servicios{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: #0f0f0f !important;
    border: 0 !important;
    border-radius: 0 !important;
    margin-bottom: 1rem!important;
    transition: 0.5s all;

}

.boton-servicios:hover{
    background-color: #FF6700 !important;    
    transition: 0.5s all;
}

.boton-servicios h4{
    text-align: left;
    width: 350px;
    padding-left: 1.4rem;
    display: flex;
    color: #ffffff;
    font-family: "Poppins-Bold"
}

@media screen and (max-width: 1400px) {
    .boton-servicios h4{
        width: 450px;
    }
}
@media screen and (max-width: 1200px) {
    .boton-servicios h4{
        width: 350px;
    }
}
@media screen and (max-width: 991px) {
    .boton-servicios h4{
        width: 500px;
    }
}

.boton-servicios h5{
    text-align: left;
    padding-left: 1.4rem;
    display: flex;
    color: #ffffff;
    font-family: "Poppins-Bold"
}

.boton-servicios h3{
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    padding-right: 0.5rem;
    color: #ffffff;
    font-family: "Poppins-Bold"
}

@media screen and (max-width: 768px) {
    .boton-servicios{
        width: 100%;
    }

    .boton-servicios h3{
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

@media screen and (max-width: 575px) {
    .boton-servicios h3{
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
}