.boton-proceso-investigacion {
  width: 100%;
  height: 90px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border: 0 !important;
  border-radius: 0 !important;
}

@media screen and (max-width: 767px) {
  .boton-proceso-investigacion {
    height: 70px;
  }
}

.color-gris-investigacion {
  background-color: #090909 !important;
}

.color-negro-investigacion {
  background-color: #000000 !important;
}
