.swiper-publicidad .swiper-button-next:after {
  margin-right: 0rem;
}

.swiper-publicidad .swiper-button-prev:after {
  margin-left: 0rem;
}

.swiper-publicidad-cel .swiper-slide {
  width: 300px;
  height: 300px;
}
